import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Kolnap from './components/Kolnap';

function App() {
  return (
    <BrowserRouter>
      <Routes>

        <Route path="*" element={<Navigate replace to="/" />} />

        <Route path="/" element={<Kolnap />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
