import styles from './Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
    const pages = [
        { name: 'About', path: '/' },
        { name: 'Contact', path: 'mailto:kolnap@protonmail.com' }
    ]

    return (
        <div className={styles.header_group}>
            <header className={styles.header}>
                <div className={styles.brand}>
                    <FontAwesomeIcon className={styles.logo} icon={faHeart} />
                    <h1 className={styles.brand_name}>Kolnap</h1>
                </div>
                <nav className={styles.horizontal_nav}>
                    <ul className={styles.horizontal_page_links}>
                        {pages.map(page => (
                            <li><a href={page.path}>{page.name}</a></li>
                        ))}
                    </ul>
                </nav>
            </header>
            <nav className={styles.vertical_nav}>
                <ul className={styles.vertical_page_links}>
                    {pages.map(page => (
                        <li><a href={page.path}>{page.name}</a></li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default Header;