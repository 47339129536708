import styles from './Main.module.css';

const Main = () => {
    return (
        <main className={styles.main}>
            <section id="about" className={styles.aboutSection}>
                <div className={styles.aboutContent}>
                    <h2>About</h2>
                    <div className={styles.verticalSeparator}></div>
                    <p>
                        Welcome to Kolnap, where cutting-edge technology meets healthcare to revolutionize the way data is managed. As an emerging start up in the HealthTech industry, our mission is to empower all stake holders within health care industry with seamless and secure data solutions that enhance patient care and drive medical innovation.
                    </p>
                </div>
            </section>
        </main>
    );
}

export default Main;